import { zodResolver } from '@hookform/resolvers/zod';
import { useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { EditForm } from './entities/edit-form';
import { useGetCurrentContentTemplateQuery } from './entities/edit-form/file-upload/hooks/use-current-content-template';
import { useValidationConfigQuery } from './entities/edit-form/file-upload/hooks/use-validation-dictionary';
import { EditFormSchemaType, getEditFormSchema } from './model/edit-form-model';

export const EditPage = () => {
  const contentTemplateQuery = useGetCurrentContentTemplateQuery();

  const validationConfigQuery = useValidationConfigQuery();

  const methods = useForm<EditFormSchemaType>({
    resolver: zodResolver(
      getEditFormSchema({
        validationConfig: validationConfigQuery.data.config,
      }),
    ),
    mode: 'all',
    reValidateMode: 'onChange',
    criteriaMode: 'all',
    defaultValues: contentTemplateQuery.data.getContentTemplate,
  });

  useEffect(() => {
    methods.reset(contentTemplateQuery.data.getContentTemplate);
  }, [contentTemplateQuery.data, methods]);

  return (
    <FormProvider {...methods}>
      <EditForm />
    </FormProvider>
  );
};
