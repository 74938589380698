import { CreatePage } from '@features/CreatePage';
import { useGetDictionariesQuery } from '@gql_codegen/classifieds-content-types';
import { formatServerError } from '@src/shared/formating/format-server-error';
import { VStack } from '@styled-system/jsx';
import { useQueryErrorResetBoundary } from '@tanstack/react-query';
import {
  createFileRoute,
  ErrorRouteComponent,
  useRouter,
} from '@tanstack/react-router';
import { Button, notification } from 'antd';
import { ComponentProps, useEffect } from 'react';

const CreatePageRouteComponent = () => {
  return <CreatePage />;
};

const ErrorComponent: ErrorRouteComponent = ({
  error,
}: ComponentProps<ErrorRouteComponent>) => {
  const router = useRouter();
  const queryErrorResetBoundary = useQueryErrorResetBoundary();

  useEffect(() => {
    // Reset the query error boundary
    queryErrorResetBoundary.reset();
  }, [queryErrorResetBoundary]);

  return (
    <VStack>
      {formatServerError(error)}
      <Button
        onClick={() => {
          // Invalidate the route to reload the loader, and reset any router error boundaries
          void router.invalidate();
        }}
      >
        retry
      </Button>
    </VStack>
  );
};

export const Route = createFileRoute('/$lang/classifieds-content/create/')({
  component: CreatePageRouteComponent,
  loader: ({ context: { queryClient } }) => {
    void queryClient.ensureQueryData({
      queryFn: useGetDictionariesQuery.fetcher(),
      queryKey: useGetDictionariesQuery.getKey(),
    });
  },
  errorComponent: ErrorComponent,
  onCatch: (error) => {
    notification.error({
      message: "Can't load data from server",
      description: formatServerError(error),
    });
  },
});
