import {
  Category,
  ContentTemplateValidationConfig,
  GetContentTemplateQuery,
} from '@gql_codegen/classifieds-content-types';
import { omit } from 'radash';
import { z } from 'zod';
import { UppyWithFormFile } from '../entities/edit-form/file-upload/hooks/use-uppy-with-form';

type GetEditFormSchemaProps = {
  validationConfig: ContentTemplateValidationConfig | undefined;
};
export const getEditFormSchema = ({
  validationConfig,
}: GetEditFormSchemaProps) => {
  const baseValidation = z.object({
    id: z.string().min(1).uuid(),
    country: z.string().min(1),
    platform: z.string().min(1),
    account: z.string().min(1),
    category: z.nativeEnum(omit(Category, ['Other'])),
    name: z.string().trim(),
    placeholders: z
      .array(
        z.object({
          position: z
            .number({ message: 'Expected number' })
            .min(1, "Can't be empty"),
          url: z
            .string()
            .min(1)
            .transform((url): string => {
              const isURL = z.string().url().safeParse(url);
              if (!isURL.success) return url;

              const newURL = new URL(url);
              if (!newURL.protocol.includes('http')) return url;

              return decodeURIComponent(newURL.pathname.slice(1));
            }),
          file: z.custom<UppyWithFormFile>().optional(),
        }),
      )
      .superRefine(
        //check for unique
        (placeholders, ctx) => {
          if (!validationConfig) return;

          if (placeholders.length < validationConfig.minPlaceholdersAllowed) {
            return ctx.addIssue({
              code: z.ZodIssueCode.custom,
              message: `At least ${validationConfig.minPlaceholdersAllowed} placeholders are required`,
            });
          }

          if (placeholders.length > validationConfig.maxPlaceholdersAllowed) {
            return ctx.addIssue({
              code: z.ZodIssueCode.custom,
              message: `No more than ${validationConfig.maxPlaceholdersAllowed} placeholders are allowed`,
            });
          }

          placeholders.map((placeholder, idx) => {
            const duplicatePlaceholders = placeholders.filter(
              (p) => p.position === placeholder.position,
            );
            if (duplicatePlaceholders.length > 1) {
              return ctx.addIssue({
                code: z.ZodIssueCode.custom,
                message: 'Placeholder positions must be unique',
                path: [idx, 'position'],
              });
            }

            if (
              placeholder.position > validationConfig.maxPlaceholderPosition
            ) {
              return ctx.addIssue({
                code: z.ZodIssueCode.custom,
                message: `Placeholder position must be less than or equal to ${validationConfig.maxPlaceholderPosition}`,
                path: [idx, 'position'],
              });
            }

            if (
              placeholder.position < validationConfig.minPlaceholderPosition
            ) {
              return ctx.addIssue({
                code: z.ZodIssueCode.custom,
                message: `Placeholder position must be greater than or equal to ${validationConfig.minPlaceholderPosition}`,
                path: [idx, 'position'],
              });
            }
          });
        },
      ),
  }) satisfies z.Schema<GetContentTemplateQuery['getContentTemplate']>;

  const otherValidation = baseValidation.merge(
    z.object({
      category: z.literal(Category.Other),
      name: z
        .string()
        .trim()
        .min(1, 'Must contain at least 1 character(s)')
        .max(25),
    }),
  ) satisfies z.Schema<GetContentTemplateQuery['getContentTemplate']>;

  return z.discriminatedUnion('category', [baseValidation, otherValidation]);
};
export type EditFormSchemaType = z.infer<ReturnType<typeof getEditFormSchema>>;
