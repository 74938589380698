import {
  ContentTemplateCreateInputSchema,
  GetContentTemplateQuery,
  useCreateContentTemplateMutation,
  useGetContentTemplateQuery,
  useGetDictionariesQuery,
  useGetTemplatesQuery,
} from '@gql_codegen/classifieds-content-types';

import { ActionBar } from '@src/shared/components/ActionBar';
import { formatServerError } from '@src/shared/formating/format-server-error';
import { useIsFetching, useIsMutating } from '@tanstack/react-query';
import { useRouter } from '@tanstack/react-router';
import { useUppyState } from '@uppy/react';
import { App, Col, Divider, Form, Input, Layout, Row, Select } from 'antd';
import { useEffect, useMemo, useState } from 'react';
import { useWatch } from 'react-hook-form';
import { FormItem } from 'react-hook-form-antd';
import { FileUpload } from '../FileUpload';
import { useUppyWithForm } from '../FileUpload/hooks/useUppy';

import { queryClient } from '@app/query-client';
import { zodResolver } from '@hookform/resolvers/zod';
import { DEFAULT_VALIDATION_CONFIG } from '@src/shared/configs/default-validation-config';
import { useConvertGeneratedQueryToSuspense } from '@src/shared/hooks/use-convert-generated-query-to-suspence';
import { css } from '@styled-system/css';
import { useForm } from 'react-hook-form';
import {
  CreateFormSchemaType,
  DefaultCreateFormValues,
  getValidationSchema,
} from '../../model/create-form-model';
import { createFormResetLogic } from './domain/create-form-reset-logic';

export const CreateForm = () => {
  const { notification } = App.useApp();
  const { data } = useConvertGeneratedQueryToSuspense(
    useGetDictionariesQuery,
    undefined,
  );
  const { mutateAsync } = useCreateContentTemplateMutation();

  const [currentSchema, setCreateFormSchema] = useState(getValidationSchema());

  const {
    control,
    formState: { errors },
    handleSubmit,
    reset,
    setValue,
    getValues,
    trigger,
  } = useForm<CreateFormSchemaType>({
    resolver: zodResolver(currentSchema),
    defaultValues: DefaultCreateFormValues,
    mode: 'all',
    reValidateMode: 'onChange',
    criteriaMode: 'all',
  });

  const router = useRouter();

  const handleSuccessSubmit = async (formData: CreateFormSchemaType) => {
    const formDataParseResult =
      ContentTemplateCreateInputSchema().safeParse(formData);
    if (!formDataParseResult.success)
      return notification.error({
        message: 'Something wrong with form data, please inform devs about it',
      });

    await mutateAsync(
      {
        createInput: formDataParseResult.data,
      },
      {
        onSuccess: (data) => {
          if (!data.createContentTemplate?.id) {
            return notification.error({
              message: 'Failed to create template',
            });
          }

          const newContentTemplate = data.createContentTemplate;
          queryClient.setQueryData<GetContentTemplateQuery>(
            useGetContentTemplateQuery.getKey({
              uuid: data.createContentTemplate.id,
            }),
            () => ({
              getContentTemplate: newContentTemplate,
            }),
          );
          void queryClient.invalidateQueries({
            queryKey: useGetTemplatesQuery.getKey(),
          });

          notification.success({
            message: (
              <span data-qa-selector="create-page-form-success-message">
                Template has been created successfully!
              </span>
            ),
          });
          router
            .navigate({
              to: `/$lang/classifieds-content/edit/$editID`,
              params: { lang: 'en', editID: data.createContentTemplate.id },
            })
            .catch((e: unknown) => {
              console.log(
                '[CreateForm::handleSuccessSubmit => Failed to navigate to Edit page: ',
                e,
              );
            });
        },
        onError: (error) => {
          console.log(
            '[CreateForm::handleSuccessSubmit => Failed to create template: ',
            error,
          );
          notification.error({
            message: (
              <span data-qa-selector="create-page-server-error-message">
                {formatServerError(error)}
              </span>
            ),
          });
        },
      },
    );
  };
  const handleInvalidSubmit = (...args: unknown[]) => {
    console.log('[CreateForm::handleInvalidSubmit::Error =>', args);
    return notification.error({
      message: (
        <span data-qa-selector="create-page-form-error-message">
          Please fix form errors
        </span>
      ),
    });
  };

  const [selectedCountry, selectedMarketplace, selectedAccount] = useWatch({
    name: ['country', 'platform', 'accountId'],
    control: control,
  });

  const selectedPlatformCode = useMemo(
    () =>
      data.getDictionaries.platforms.find(
        (platform) => platform.id === selectedMarketplace,
      )?.name,
    [selectedMarketplace, data.getDictionaries.platforms],
  );

  const validationConfig = useMemo(
    () =>
      data.getDictionaries.classifiedsContentTemplateValidationConfigs.find(
        (config) => config.classifiedCode === selectedPlatformCode,
      )?.config ?? DEFAULT_VALIDATION_CONFIG,
    [
      data.getDictionaries.classifiedsContentTemplateValidationConfigs,
      selectedPlatformCode,
    ],
  );

  useEffect(() => {
    setCreateFormSchema(getValidationSchema(validationConfig));
  }, [validationConfig]);

  const { handleFileUpload, uppy, placeholders, remove, clearUppy } =
    useUppyWithForm({
      validationConfig,
      control,
      getValues,
      trigger,
    });

  const uploads = useUppyState(uppy, (state) => state.currentUploads);

  const isUploading = Object.keys(uploads).length > 0;
  const isMutating = useIsMutating() > 0;
  const isFetching = useIsFetching() > 0;

  const { categories, contentTemplateCountries, accounts, platforms } =
    data.getDictionaries;

  const mappedTemplateCountries = useMemo(() => {
    return contentTemplateCountries.map((country) => ({
      label: (
        <span data-qa-selector="create-page-country-option">
          {country.country}
        </span>
      ),
      value: country.country,
    }));
  }, [contentTemplateCountries]);

  const mappedPlatforms = useMemo(() => {
    return platforms
      .map((platform) => ({
        label: (
          <span data-qa-selector="create-page-marketplace-option">
            {platform.name}
          </span>
        ),
        value: platform.id,
        country: platform.country,
      }))
      .filter((platform) => platform.country === selectedCountry);
  }, [platforms, selectedCountry]);

  const mappedAccounts = useMemo(() => {
    return accounts
      .map((account) => ({
        label: (
          <span data-qa-selector="create-page-account-option">
            {account.name}
          </span>
        ),
        value: account.id,
        platrofmId: account.platformId,
      }))
      .filter((account) => account.platrofmId === selectedMarketplace);
  }, [accounts, selectedMarketplace]);

  const handleResetLogic = createFormResetLogic({ setValue, clearUppy });

  return (
    <Form
      layout="vertical"
      noValidate={true}
      onFinish={(event: React.BaseSyntheticEvent) => {
        void handleSubmit(handleSuccessSubmit, handleInvalidSubmit)(event);
      }}
      onReset={() => {
        clearUppy();
        reset();
      }}
    >
      <ActionBar inProgress={isFetching || isMutating || isUploading} />
      <Layout style={{ backgroundColor: 'transparent' }}>
        <Row gutter={24} className={css({ marginBottom: 4 })}>
          <Col span={6}>
            <FormItem
              control={control}
              name="country"
              label="Country"
              required
              data-qa-selector="create-page-country"
              help={<div>{errors.country?.message}</div>}
            >
              <Select
                onChange={() => {
                  handleResetLogic('country');
                }}
                options={mappedTemplateCountries}
                getPopupContainer={(triggerNode: HTMLElement) =>
                  triggerNode.parentElement ?? document.body
                }
                data-qa-selector="create-page-country-select"
              />
            </FormItem>
          </Col>
          <Col span={6}>
            <FormItem
              control={control}
              name="platform"
              label="Marketplace"
              required
              data-qa-selector="create-page-marketplace"
              help={
                <div>
                  {errors.platform?.message}
                  {!selectedCountry && 'Please select Country'}
                </div>
              }
            >
              <Select
                disabled={!selectedCountry}
                onChange={() => {
                  handleResetLogic('platform');
                }}
                options={mappedPlatforms}
                getPopupContainer={(triggerNode: HTMLElement) =>
                  triggerNode.parentElement ?? document.body
                }
                data-qa-selector="create-page-marketplace-select"
              />
            </FormItem>
          </Col>
          <Col span={6}>
            <FormItem
              control={control}
              name="accountId"
              label="Account"
              required
              data-qa-selector="create-page-account"
              help={
                <div>
                  {errors.accountId?.message}
                  {!selectedMarketplace &&
                    'Please select Country and Marketplace'}
                </div>
              }
            >
              <Select
                disabled={!selectedMarketplace}
                options={mappedAccounts}
                getPopupContainer={(triggerNode: HTMLElement) =>
                  triggerNode.parentElement ?? document.body
                }
                data-qa-selector="create-page-account-select"
              />
            </FormItem>
          </Col>
          <Col span={6}>
            <FormItem
              control={control}
              name="category"
              label="Category"
              required
              data-qa-selector="create-page-category"
              help={<div>{errors.category?.message}</div>}
            >
              <Select
                disabled
                options={categories.map((categorie) => ({
                  label: (
                    <span data-qa-selector="create-page-category-option">
                      {categorie}
                    </span>
                  ),
                  value: categorie,
                }))}
                getPopupContainer={(triggerNode: HTMLElement) =>
                  triggerNode.parentElement ?? document.body
                }
                data-qa-selector="create-page-category-select"
              />
            </FormItem>
          </Col>
        </Row>
        <Row gutter={24} className={css({ marginBottom: 4 })}>
          <Col span={12}>
            <FormItem
              control={control}
              name="name"
              label="Content Template Name"
              required
              data-qa-selector="create-page-name"
              help={<div>{errors.category?.message}</div>}
            >
              <Input
                maxLength={25}
                count={{
                  max: 25,
                  show: ({ count, maxLength }) => (
                    <span data-qa-selector="create-page-name-counter">{`${count}/${maxLength ?? 25}`}</span>
                  ),
                }}
                data-qa-selector="create-page-name-input"
              />
            </FormItem>
          </Col>
        </Row>
        <Row gutter={24}>
          <Divider
            orientation="left"
            className={css({
              my: '8 !important',
            })}
          >
            <span
              className={css({
                fontSize: '2xl',
                color: 'gray.400',
              })}
            >
              Placeholders
            </span>
          </Divider>
          <Col>
            <FileUpload
              validationConfig={validationConfig}
              control={control}
              handleFileUpload={handleFileUpload}
              uppy={uppy}
              placeholders={placeholders}
              remove={remove}
              formErrors={errors}
            />

            {!selectedAccount && (
              <p>
                Please select Country, Marketplace and Account before uploading
              </p>
            )}
          </Col>
        </Row>
      </Layout>
    </Form>
  );
};
