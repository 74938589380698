import { DeleteOutlined, UploadOutlined } from '@ant-design/icons';
import { CreateFormSchemaType } from '@features/CreatePage/model/create-form-model';
import {
  ContentTemplateValidationConfig,
  PlaceholderImageInput,
} from '@gql_codegen/classifieds-content-types';
import { css } from '@styled-system/css';
import { Bleed, Grid, VStack } from '@styled-system/jsx';
import '@uppy/core/dist/style.css';
import '@uppy/dashboard/dist/style.css';
import '@uppy/drop-target/dist/style.css';
import { useUppyState } from '@uppy/react';
import { Button, Image, InputNumber, Typography } from 'antd';
import { Progress } from 'antd/lib';
import { useRef } from 'react';
import {
  Control,
  FieldErrors,
  UseFieldArrayRemove,
  useWatch,
} from 'react-hook-form';
import { FormItem } from 'react-hook-form-antd';
import { UppyWithForm } from './hooks/useUppy';

type FileUploadProps = {
  uppy: UppyWithForm;
  placeholders: PlaceholderImageInput[];
  control: Control<CreateFormSchemaType>;
  formErrors: FieldErrors<CreateFormSchemaType>;
  remove: UseFieldArrayRemove;
  handleFileUpload: (event: React.ChangeEvent<HTMLInputElement>) => void;
  validationConfig: ContentTemplateValidationConfig;
};

export const FileUpload = (props: FileUploadProps) => {
  const {
    validationConfig,
    uppy,
    placeholders,
    control,
    formErrors,
    handleFileUpload,
    remove,
  } = props;
  const fileInputRef = useRef<HTMLInputElement>(null);

  const files = useUppyState(props.uppy, (state) => state.files);

  const [accountId] = useWatch({
    control,
    name: ['accountId'],
  });

  return (
    <VStack alignItems="start">
      <VStack alignItems="start" data-qa-selector="create-form-upload">
        <input
          type="file"
          accept="image/*"
          ref={fileInputRef}
          className={css({ display: 'none !important' })}
          multiple
          onChange={handleFileUpload}
        />
        <Button
          type="primary"
          size="large"
          disabled={
            placeholders.length >= validationConfig.maxPlaceholdersAllowed ||
            !accountId
          }
          icon={<UploadOutlined />}
          onClick={() => fileInputRef.current?.click()}
          data-qa-selector="create-form-upload-button"
          className={css({ my: 4 })}
        >
          Upload
          {accountId && (
            <span>
              (Min: {validationConfig.minPlaceholdersAllowed} - Max:{' '}
              {validationConfig.maxPlaceholdersAllowed} placeholders)
            </span>
          )}
        </Button>
        <Typography.Text
          type="danger"
          role="alert"
          style={{
            visibility:
              (formErrors.placeholders?.root ?? formErrors.placeholders)
                ? 'visible'
                : 'hidden',
          }}
          data-qa-selector="create-form-placeholder-error"
        >
          {formErrors.placeholders?.message}
          {formErrors.placeholders?.root?.message}
        </Typography.Text>
      </VStack>

      <Grid alignItems="start" gap={8} gridTemplateColumns={4}>
        {placeholders.map((placeholder, idx) => {
          const file = Object.values(files).find(
            (file) =>
              file.preview === placeholder.url ||
              file.uploadURL?.includes(placeholder.url),
          );

          return (
            <VStack
              key={`${idx}_${placeholder.url}`}
              alignItems="start"
              gap={2}
            >
              <Progress
                percent={file?.progress.percentage ?? 0}
                style={{
                  visibility:
                    file?.progress.uploadStarted &&
                    !file.progress.uploadComplete
                      ? 'visible'
                      : 'hidden',
                }}
                data-qa-selector="create-form-placeholder-progressbar"
              />

              <FormItem
                control={control}
                name={`placeholders.${idx}.position`}
                label="Position"
                required
                help={`Min: ${validationConfig.minPlaceholderPosition} - Max: ${validationConfig.maxPlaceholderPosition}`}
                data-qa-selector="create-form-placeholder-position"
              >
                <InputNumber
                  min={validationConfig.minPlaceholderPosition}
                  max={validationConfig.maxPlaceholderPosition}
                  data-qa-selector="create-form-placeholder-position-input"
                  className={css({ width: '120px' })}
                  addonAfter={
                    <Bleed inline={'11px'}>
                      <Button
                        onClick={() => {
                          remove(idx);
                          uppy.removeFile(file?.id ?? '');
                        }}
                        data-qa-selector="create-form-placeholder-delete-button"
                      >
                        <DeleteOutlined
                          style={{ color: 'red', fontSize: '18px' }}
                        />
                      </Button>
                    </Bleed>
                  }
                />
              </FormItem>

              {(file?.preview ?? placeholder.url) && (
                <Image
                  src={file?.preview ?? placeholder.url}
                  data-qa-selector="create-form-placeholder-image"
                />
              )}
            </VStack>
          );
        })}
      </Grid>
    </VStack>
  );
};
